import React, { Component } from "react"
import { Form, Button } from "react-bootstrap"

import { api_gateway_key } from "../config"

import Layout from "../components/layout"
import Loader from "../components/loader"

class Contact extends Component {

    state = {
        loading: false,
        message_sent: false,
    }
   
    handleSubmit = (event) => {
    
        event.preventDefault();

        this.setState({ loading: true });

        const target = event.target
        const email = target.email.value
        const message = target.message.value
        const data = {
            'email': email,
            'message': message,
        }
    
        if (!data.email || !data.message) {
            alert('Please enter email and message')
            return false
        }
    
        fetch('https://wa7in43r27.execute-api.eu-west-2.amazonaws.com/beta', {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-api-key": api_gateway_key
            }
        }).then(response => {
            return response.json()
        }).then((resp) => {
            const message_sent = "Thank you! Your message has been sent successfully.";
            this.setState({ loading: false, message_sent: message_sent})
        }).catch((error) => {
            console.log('error', error)
            const message_sent = "Thank you! Your message has been sent successfully.";
            this.setState({ loading: false, message_sent: message_sent})
        })
    }

    render () {

        let form = null;
        let message_sent_div = null;
        
        if (this.state.message_sent !== false) {
            message_sent_div = (
                <div className="message_sent_container">
                    <span className="message_sent_tick">&#10003;</span>&nbsp;
                    <div className="message_sent_div">{this.state.message_sent}</div>
                </div>
            )
        }

        if (this.state.loading === false) {
            form = (                    
                <div>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="contactForm">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                placeholder="Enter your email address" 
                                name="email"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                required
                                as="textarea" 
                                rows="3" 
                                placeholder="Enter your messsage" 
                                name="message"/>
                        </Form.Group>
                        <Form.Group>
                            <Button variant="outline-danger" type="submit">Send Message</Button>
                        </Form.Group>
                    </Form>
                </div>
            );
        } else {
            form = (
                <div>
                    <Loader />
                </div>
            )
        }

        return (
            <Layout>
                <h1 className="page-title">Contact me</h1>
                <p>
                    If you would like to contact me you can <a href="mailto:pomalley83@gmail.com" className="list-link">email me directly</a> or you can send me a message using the contact form below.
                </p>
                {message_sent_div}
                {form}
            </Layout>
        )
    }
}

export default Contact;